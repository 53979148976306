/* eslint-disable react/prop-types */
import { ArrowForward, Article, Business, MoreVert, Store, Storefront } from "@mui/icons-material";
import { Button, Card, CardActions, CardContent, CardHeader, CardMedia, Checkbox, Chip, IconButton, Stack, Typography } from "@mui/material";

import dayjs from "dayjs";
// import { useNavigate, useOutletContext } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import supabase from "../config/supabaseClient";
import toast, { Toaster } from "react-hot-toast";
import { entityStatusColor, entityStatusTranslation, upperCaseAllWords, uppercaseFirstLetter } from "../utilities/tools";
import { bgBG } from "@mui/x-data-grid";
import { useAuth } from "../contexts/useAuth";
import { addAuditTrailEntry } from "../utilities/audit";

export default function Entity({ entity }) {
  const { user } = useAuth();
  const navigate = useNavigate();

  const handleCardMoreClick = () => {
    console.log("more clicked");
  };

  const handleSelectEntity = async () => {
    // update the entity status to in progress in supabase
    try {
      const { data, error } = await supabase.from("entity").update({ status: "In-Progress" }).eq("id", entity.id);

      if (error) {
        console.log("update entity.status error: ", error);
      } else {
        console.log("update entity.status data: ", data);
        // notify();
        // navigate(`/newcorp/${entity.id}`);
        navigate(`/setup/entity/${entity.id}`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpenEntity = async () => {
    // check if the entity has a user_role record in the supabase user_role table
    const { data, error } = await supabase.from("user_role").select("*").eq("entity_id", entity.id).eq("member_id", entity.linked_user);

    if (error) {
      console.log("error getting user_role record: ", error);
    } else {
      if (data.length > 0) {
        console.log("user_role record: ", data);
      } else {
        console.log("no user_role record");

        // create a user_role record
        const { data, error } = await supabase
          .from("user_role")
          .insert({ entity_id: entity.id, member_id: entity.linked_user, role: "customer" })
          .select();
        if (error) {
          console.log("error creating user_role record: ", error);
        } else {
          console.log("user_role record created: ", data);
          addAuditTrailEntry({
            entity_id: entity.id,
            type: "create:user_role",
            app: "customer_app",
            app_context: "entity",
            user_id: user.id,
            description: "User role created for entity created from website on first time click",
          });
        }
      }
    }

    navigate(`/view/${entity.id}`);
  };

  return (
    <div onClick={handleOpenEntity}>
      <Card
        variant="outlined"
        sx={{
          width: { xs: "380px", sm: "320px", md: "320px" },
          // width: "320px",
          height: "150px",
          margin: 1,
          bgcolor: "card.main",
          cursor: "pointer",
          ":hover": { bgcolor: "card.white", border: 1, borderColor: "primary.main" },
        }}
      >
        <CardContent>
          <Stack direction="row" spacing={2} alignItems="center">
            <Card variant="outlined" sx={{ bgcolor: "card.white", borderRadius: 2, p: 0, width: "80px", height: "60px" }}>
              <Storefront color="primary" sx={{ width: "60px", height: "60px" }} />
            </Card>
            <Stack direction="column" spacing={1} alignItems="start" width={"100%"}>
              <Typography fontWeight={500} color="text.secondary">
                {entity?.english_name}
                {/* {!entity?.name && !entity?.is_numbered && "Name Pending"}
                {entity?.name && !entity?.is_numbered && uppercaseFirstLetter(entity?.name)}
                {!entity?.name && entity?.is_numbered && "### Ontario Inc."} {entity?.name && entity?.is_numbered && upperCaseAllWords(entity?.name)} */}
                {/* {!entity?.is_numbered && entity?.legal_element_ending} */}
                {/* Incorporated: {dayjs(entity?.created_at).format("MMM/DD/YYYY")} */}
              </Typography>
              <Typography color="text.secondary" sx={{ fontSize: "12px" }}>
                {entity?.is_numbered ? "Numbered" : "Named"}
              </Typography>

              {entity?.date_incorporated ? (
                <Stack direction="column" spacing={1} alignItems="start" width={"100%"}>
                  <Typography variant="body2" color="text.secondary">
                    {dayjs(entity?.date_incorporated).format("MMM/DD/YYYY")}
                  </Typography>
                  <Stack direction="row" spacing={1} display={"flex"} justifyContent={"space-between"} width={"100%"}>
                    <Chip
                      size="small"
                      sx={{
                        color: "white",
                        bgcolor: entityStatusTranslation(entity?.status).color,
                      }}
                      label={entityStatusTranslation(entity?.status).label}
                    />
                    <ArrowForward sx={{ color: "text.secondary" }} />
                  </Stack>
                </Stack>
              ) : (
                <Stack direction="row" spacing={1} display={"flex"} justifyContent={"space-between"} width={"100%"}>
                  <Chip
                    size="small"
                    sx={{
                      color: "white",
                      bgcolor: entityStatusTranslation(entity?.status).color,
                    }}
                    label={entityStatusTranslation(entity?.status).label}
                  />
                  <ArrowForward sx={{ color: "text.secondary" }} />
                </Stack>
              )}
            </Stack>
          </Stack>
          <CardActions sx={{ justifyContent: "flex-end", p: 0, m: 0 }}></CardActions>
        </CardContent>
        {/* <CardActions disableSpacing sx={{ justifyContent: "space-between" }}>
        <Chip
          sx={{
            color: "white",
            bgcolor: entity.status != "Completed" ? "entityStatuses.inProgress" : "entityStatuses.completed",
          }}
          label={entity?.status ? uppercaseFirstLetter(entity.status) : "none"}
        />
        {entity?.status === "Completed" ? (
          <Button variant="contained" size="small" disabled>
            View
          </Button>
        ) : (
          <Button
            // disabled={entity?.status === "processing"}
            variant="contained"
            size="small"
            onClick={() =>
              // setSelectedEntity(entity.id), navigate(`/create/${entity.id}`)
              // navigate(`/newcorp/${entity.id}`)
              handleSelectEntity()
            }
          >
            Continue
          </Button>
        )}
      </CardActions> */}
      </Card>
    </div>
  );
}
