import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../contexts/useAuth";
import { AppBar, Box, Card, CardContent, Stack, Step, StepLabel, Stepper, Typography } from "@mui/material";
import Step4a from "../forms/Step4a";
import supabase from "../config/supabaseClient";
import Step1 from "../forms/Step1";
import StepHeader from "../components/StepHeader";
import Step100 from "../forms/Step100";
import Step101 from "../forms/Step101";
import Step3 from "../forms/Step3";
import Step2 from "../forms/Step2";
import Step5 from "../forms/Step5";
import Step6 from "../forms/Step6";
import { HelpPopover } from "../components/HelpPopover";
import _, { set } from "lodash";
import Payment from "../forms/Payment";
import Step7 from "../forms/Step7";
import Step8 from "../forms/Step8";

export default function NewCorp() {
  // Set Current User
  const { user } = useAuth();

  // Set Entity Data
  const [entityData, setEntityData] = useState({});

  // Manage Stepper State
  const [activeStep, setActiveStep] = useState(1000);

  //Set Entity ID
  let { eid } = useParams();

  // window size
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Step names

  const steps = [
    "Company Name",
    "Company Activity",
    "Company Address",
    "Shareholder / Director Details",
    "Financial Year End",
    "Final Review",
    "Choose Your Package",
    "Launch!",
  ];

  const nextStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    console.log(activeStep);
  };

  const prevStep = (step) => {
    console.log("Received Step: ", step);
    if (typeof step === "number") {
      setActiveStep(step);
      console.log("app set the step to: ", step);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      console.log("app set step -1");
    }
  };

  const updateEntityLastStep = async (step) => {
    const { data, error } = await supabase.from("entity").update({ last_step: step }).eq("id", eid).single();

    if (error) {
      console.log(error);
    } else {
      console.log("Entity Last Step Updated");
    }
  };

  useEffect(() => {
    // Get Entity Last Step when the entity is loaded or the eid changes
    const getEntityLastStep = async () => {
      const { data, error } = await supabase.from("entity").select("*").eq("id", eid).single();

      if (error) {
        console.log(error);
      } else {
        setActiveStep(data.last_step);
        setEntityData(data);
        console.log("(newcorp.jsx) useEffect: ", data.last_step);
        console.log("is_numbered: ", data.is_numbered);
      }
    };

    getEntityLastStep();

    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [eid]);

  switch (activeStep) {
    case 0:
      return (
        <>
          <Box sx={{ m: "20px" }}>
            <StepHeader title="HERE WE GO!!" subtitle="First, tell us how you want to name your company" img_src={"/header/startup.svg"} />
            {/* <StepHeader title="HERE WE GO!!" subtitle="First, tell us how you want to name your company" img_src={"/storefront.svg"} /> */}
            <Card variant="outlined" sx={{ bgcolor: "card.main" }}>
              <CardContent sx={{ padding: "30px" }}>
                <Step1 handleNext={nextStep} handleBack={prevStep} activeStep={activeStep} setActiveStep={setActiveStep} />
              </CardContent>
            </Card>
          </Box>
          <Box position={"sticky"} sx={{ width: "100%", mt: 10, py: 3, bottom: 0, bgcolor: "white" }} zIndex={"999"}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label, index) => (
                <Step key={label} onClick={() => setActiveStep(index)}>
                  <StepLabel>
                    <Typography fontSize={"12px"}>{windowWidth <= 600 ? "" : label}</Typography>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
        </>
      );
    case 1:
      return (
        <>
          <Box sx={{ m: "20px" }}>
            <StepHeader
              title="SOUNDS GOOD!"
              subtitle={`Next, we need the 'NAICS Code' for your company. Click the SEARCH button below to look up the NAICS Code based on your company’s primary activity (e.g., coffee shop). Type a word associated with the activity and select the closest result from the list.`}
              img_src={"/header/information.svg"}
            >
              <HelpPopover>
                <Stack spacing={2} p={3} maxWidth={300}>
                  <Typography sx={{ p: 2 }}>
                    The{" "}
                    <a href="https://www23.statcan.gc.ca/imdb/p3VD.pl?Function=getVD&TVD=1181553" target="_blank" rel="noreferrer">
                      NAICS Code
                    </a>{" "}
                    is a classification system of business activities used in North America for statistical purposes and is required in Ontario for
                    new company incorporations.
                  </Typography>
                </Stack>
              </HelpPopover>
            </StepHeader>
            <Card variant="outlined" sx={{ bgcolor: "card.main" }}>
              <CardContent sx={{ padding: "30px" }}>
                <Step2 handleNext={nextStep} handleBack={prevStep} setActiveStep={setActiveStep} />
              </CardContent>
            </Card>
            <Box position={"sticky"} sx={{ width: "100%", mt: 10, py: 3, bottom: 0, bgcolor: "white" }} zIndex={"999"}>
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label, index) => (
                  <Step key={label} onClick={() => setActiveStep(index)}>
                    <StepLabel>
                      <Typography fontSize={"12px"}>{windowWidth <= 600 ? "" : label}</Typography>
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </Box>
        </>
      );
    case 2:
      // Step 3 - Confirm Company Address
      return (
        <>
          <Box sx={{ m: "20px" }}>
            <StepHeader
              title="GOOD WORK!"
              subtitle=" The hard part is over. We just need to confirm a few more
              details with you and you'll be all set!"
              img_src={"/header/map.svg"}
            />
            <Card variant="outlined" sx={{ bgcolor: "card.main" }}>
              <CardContent sx={{ padding: "30px" }}>
                <Step3 handleNext={nextStep} handleBack={prevStep} setActiveStep={setActiveStep} activeStep={activeStep} />
              </CardContent>
            </Card>
            <Box position={"sticky"} sx={{ width: "100%", mt: 10, py: 3, bottom: 0, bgcolor: "white" }} zIndex={"999"}>
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label, index) => (
                  <Step key={label} onClick={() => setActiveStep(index)}>
                    <StepLabel>
                      <Typography fontSize={"12px"}>{windowWidth <= 600 ? "" : label}</Typography>
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </Box>
        </>
      );
    case 3:
      // Step 4a
      return (
        <>
          <Box sx={{ m: "20px" }}>
            <StepHeader
              title="GREAT!"
              subtitle="We need some information now to get you set up the sole shareholder, director and officer of the new corporation. You can add other shareholders, directors and officers after incorporation with the help of a lawyer."
              img_src={"/header/manager.svg"}
            ></StepHeader>

            <Card variant="outlined" sx={{ bgcolor: "card.main" }}>
              <CardContent sx={{ padding: "30px" }}>
                <Step4a
                  handleNext={nextStep}
                  handleBack={prevStep}
                  setActiveStep={setActiveStep}
                  activeStep={activeStep}
                  updateEntityLastStep={updateEntityLastStep}
                />
              </CardContent>
            </Card>

            <Box position={"sticky"} sx={{ width: "100%", mt: 10, py: 3, bottom: 0, bgcolor: "white" }} zIndex={"999"}>
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label, index) => (
                  <Step key={label} onClick={() => setActiveStep(index)}>
                    <StepLabel>
                      <Typography fontSize={"12px"}>{windowWidth <= 600 ? "" : label}</Typography>
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </Box>
        </>
      );

    case 4:
      // Step 5 - Confirm Financial Year End
      return (
        <>
          <Box sx={{ m: "20px" }}>
            <StepHeader
              title="ALMOST THERE!"
              subtitle="Finally, tell us what you want your company’s financial year end date to be - talk to your accountant for guidance. You can also change it after you incorporate with the help of a lawyer. "
              img_src={"/header/fiscal-year-end.svg"}
              helpcomponent={
                <HelpPopover>
                  <Stack spacing={2} p={5} maxWidth={390}>
                    <Typography>
                      A corporation’s financial year is also its fiscal period for accounting and tax purposes. A corporation’s first financial year
                      begins on the date of incorporation and ends on a year end date chosen by the owner. But a financial year cannot be longer than
                      53 weeks (371 days).
                    </Typography>
                    <Typography>
                      The most common financial year end dates are <b>March 31</b>, <b>June 30</b>, <b>September 30</b> and <b>December 31</b>.
                      Consider choosing a financial year end date that falls just within a year from incorporation date so you have as much time as
                      possible to file your initial T2 tax return without going over the 53-week limit.
                    </Typography>
                    <Typography>
                      If you decide now, we will include this in your corporation’s organizational resolutions so you’ll be all set. You can also
                      decide later by declaring your financial year end date on your first T2 return after incorporation (which is due within 6 months
                      of your company’s financial year).
                    </Typography>
                  </Stack>
                </HelpPopover>
              }
            >
              <HelpPopover>
                <Stack spacing={2} p={5} maxWidth={390}>
                  <Typography>
                    A corporation’s financial year is also its fiscal period for accounting and tax purposes. A corporation’s first financial year
                    begins on the date of incorporation and ends on a year end date chosen by the owner. But a financial year cannot be longer than 53
                    weeks (371 days).
                  </Typography>
                  <Typography>
                    The most common financial year end dates are <b>March 31</b>, <b>June 30</b>, <b>September 30</b> and <b>December 31</b>. Consider
                    choosing a financial year end date that falls just within a year from incorporation date so you have as much time as possible to
                    file your initial T2 tax return without going over the 53-week limit.
                  </Typography>
                  <Typography>
                    If you decide now, we will include this in your corporation’s organizational resolutions so you’ll be all set. You can also decide
                    later by declaring your financial year end date on your first T2 return after incorporation (which is due within 6 months of your
                    company’s financial year).
                  </Typography>
                </Stack>
              </HelpPopover>
            </StepHeader>

            <Card variant="outlined" sx={{ bgcolor: "card.main" }}>
              <CardContent sx={{ padding: "30px" }}>
                <Step5 handleNext={nextStep} handleBack={prevStep} setActiveStep={setActiveStep} updateEntityLastStep={updateEntityLastStep} />
              </CardContent>
            </Card>
            <Box position={"sticky"} sx={{ width: "100%", mt: 10, py: 3, bottom: 0, bgcolor: "white" }} zIndex={"999"}>
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label, index) => (
                  <Step key={label} onClick={() => setActiveStep(index)}>
                    <StepLabel>
                      <Typography fontSize={"12px"}>{windowWidth <= 600 ? "" : label}</Typography>
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </Box>
        </>
      );

    case 5:
      // Step 6 - Final Review
      return (
        <>
          <Box sx={{ m: "20px" }}>
            <StepHeader title="PHEW" subtitle="That wasn’t too bad, was it?" img_src={"/header/approval.svg"} />
            <Card variant="outlined" sx={{ bgcolor: "card.main" }}>
              <CardContent sx={{ padding: "30px" }}>
                <Step6 handleNext={nextStep} handleBack={prevStep} setActiveStep={setActiveStep} updateEntityLastStep={updateEntityLastStep} />
              </CardContent>
            </Card>
            <Box position={"sticky"} sx={{ width: "100%", mt: 10, py: 3, bottom: 0, bgcolor: "white" }} zIndex={"999"}>
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label, index) => (
                  <Step key={label} onClick={() => setActiveStep(index)}>
                    <StepLabel>
                      <Typography fontSize={"12px"}>
                        <Typography fontSize={"12px"}>{windowWidth <= 600 ? "" : label}</Typography>
                      </Typography>
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
            {/* <pre>{windowWidth}</pre> */}
          </Box>
        </>
      );
    case 6:
      // Step 7 - (Previously Payment) Review and Launch
      return (
        <>
          <Box sx={{ m: "20px" }}>
            <StepHeader
              title="Confirm Your Purchase"
              subtitle="Please indicate below if you would like to become a SkyLaunch Member for an annual fee after incorporation and save $50 on the Incorporation Package."
              img_src={"/header/payment.svg"}
            ></StepHeader>
            <Card variant="outlined" sx={{ bgcolor: "card.main" }}>
              <CardContent sx={{ padding: "30px" }}>
                {/* <Payment handleNext={nextStep} handleBack={prevStep} setActiveStep={setActiveStep} /> */}
                <Step7 handleNext={nextStep} handleBack={prevStep} setActiveStep={setActiveStep} updateEntityLastStep={updateEntityLastStep} />
              </CardContent>
            </Card>
            <Box position={"sticky"} sx={{ width: "100%", mt: 10, py: 3, bottom: 0, bgcolor: "white" }} zIndex={"999"}>
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label, index) => (
                  <Step key={label} onClick={() => setActiveStep(index)}>
                    <StepLabel>
                      <Typography fontSize={"12px"}>
                        <Typography fontSize={"12px"}>{windowWidth <= 600 ? "" : label}</Typography>
                      </Typography>
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
            {/* <pre>{windowWidth}</pre> */}
          </Box>
        </>
      );
    case 7:
      // Step 8 - Consent and signature
      return (
        <>
          <Box sx={{ m: "20px" }}>
            <StepHeader
              title="Consent & Authorization"
              subtitle="Please authorize Skylaunch to handle your company registration"
              img_src={"/header/approval.svg"}
            ></StepHeader>
            <Card variant="outlined" sx={{ bgcolor: "card.main" }}>
              <CardContent sx={{ padding: "30px" }}>
                {/* <Payment handleNext={nextStep} handleBack={prevStep} setActiveStep={setActiveStep} /> */}
                <Step8 handleNext={nextStep} handleBack={prevStep} setActiveStep={setActiveStep} updateEntityLastStep={updateEntityLastStep} />
              </CardContent>
            </Card>
            <Box position={"sticky"} sx={{ width: "100%", mt: 10, py: 3, bottom: 0, bgcolor: "white" }} zIndex={"999"}>
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label, index) => (
                  <Step key={label} onClick={() => setActiveStep(index)}>
                    <StepLabel>
                      <Typography fontSize={"12px"}>
                        <Typography fontSize={"12px"}>{windowWidth <= 600 ? "" : label}</Typography>
                      </Typography>
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
            {/* <pre>{windowWidth}</pre> */}
          </Box>
        </>
      );

    case 100:
      // Named company and needs nuans report
      return (
        <>
          <StepHeader
            title="SUPER!"
            subtitle="You've told us you want a named company and would like us to obtain a NUANS Name Reservation Report for you."
            img_src={"/storefront.svg"}
          />
          {/* step form component here */}
          <Card variant="outlined" sx={{ bgcolor: "card.main" }}>
            <CardContent sx={{ padding: "30px" }}>
              <Step100 handleNext={nextStep} handleBack={prevStep} activeStep={activeStep} />
            </CardContent>
          </Card>
          <Box sx={{ width: "100%", mt: 10 }}>
            <Stepper activeStep={0} alternativeLabel>
              {steps.map((label, index) => (
                <Step key={label} onClick={() => setActiveStep(index)}>
                  <StepLabel>
                    <Typography fontSize={"12px"}>{windowWidth <= 600 ? "" : label}</Typography>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
        </>
      );
    case 101:
      // Nuans report pre-submission summary
      return (
        <>
          <StepHeader
            title="NICE!"
            subtitle="Our pre-search indicates that your proposed name does not conflict
          with any existing names and is likely acceptable, however this is not
          a guarantee."
            img_src={"/storefront.svg"}
          />
          {/* step form component here */}
          <Card variant="outlined" sx={{ bgcolor: "card.main" }}>
            <CardContent sx={{ padding: "30px" }}>
              <Step101 handleNext={nextStep} handleBack={prevStep} activeStep={activeStep} />
            </CardContent>
          </Card>

          <AppBar position="sticky" color="default" sx={{ top: "auto", bottom: 0 }}>
            <Box sx={{ width: "100%", mt: 5 }}>
              <Stepper activeStep={0} alternativeLabel>
                {steps.map((label, index) => (
                  <Step key={label} onClick={() => setActiveStep(index)}>
                    <StepLabel>
                      <Typography fontSize={"12px"}>{windowWidth <= 600 ? "" : label}</Typography>
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </AppBar>
        </>
      );
    default:
      return null;
  }
}
