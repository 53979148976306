import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { HelpPopover } from "../components/HelpPopover";
import { ErrorMessage, useFormik } from "formik";
import * as Yup from "yup";
import supabase from "../config/supabaseClient";
// import { useAuth } from "../contexts/useAuth";;
import { useAuth } from "../contexts/useAuth";
import { Form, useParams } from "react-router-dom";

import { ExpandMore } from "@mui/icons-material";
// import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import countries from "../data/countries.json";
import share_options from "../data/share_options.json";
import share_prices from "../data/share_prices.json";
import officer_titles from "../data/officer_titles.json";
import { set } from "lodash";
import { sub } from "date-fns";
import toast from "react-hot-toast";

// Form Validation Schema using Yup
const validationSchema = Yup.object().shape({
  use_registered_address: Yup.boolean(),
  first_name: Yup.string().required("Required"),
  last_name: Yup.string().required("Required"),
  middle_name: Yup.string(),
  email: Yup.string().email("Invalid email address").required("Required"),

  is_canadian_resident: Yup.boolean(),
  is_incorporator: Yup.boolean(),
  corporation_name: Yup.string(),
  corporation_number: Yup.string(),
  b_is_standard_address: Yup.boolean(),
  b_lot_address: Yup.string().when("b_is_standard_address", {
    is: false,
    then: (schema) => schema.required("Required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  b_street_address: Yup.string().when("b_is_standard_address", {
    is: true,
    then: (schema) => schema.required("Required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  b_street_name: Yup.string().when("b_is_standard_address", {
    is: true,
    then: (schema) => schema.required("Required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  b_unit: Yup.string(),
  b_city: Yup.string().when("b_is_standard_address", {
    is: true,
    then: (schema) => schema.required("Required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  b_country: Yup.string().when("b_is_standard_address", {
    is: true,
    then: (schema) => schema.required("Required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  b_jurisdiction: Yup.string().when("b_is_standard_address", {
    is: true,
    then: (schema) => schema.required("Required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  b_postal_code: Yup.string().when("b_is_standard_address", {
    is: true,
    then: (schema) => schema.required("Required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  is_corporation: Yup.boolean(),
  title: Yup.string(),
  r_is_standard_address: Yup.boolean(),
  r_lot_address: Yup.string().when("r_is_standard_address", {
    is: false,
    then: (schema) => schema.required("Required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  r_street_address: Yup.string().when("r_is_standard_address", {
    is: true,
    then: (schema) => schema.required("Required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  r_street_name: Yup.string().when("r_is_standard_address", {
    is: true,
    then: (schema) => schema.required("Required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  r_unit: Yup.string(),
  r_city: Yup.string().when("r_is_standard_address", {
    is: true,
    then: (schema) => schema.required("Required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  r_country: Yup.string().when("r_is_standard_address", {
    is: true,
    then: (schema) => schema.required("Required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  r_jurisdiction: Yup.string().when("r_is_standard_address", {
    is: true,
    then: (schema) => schema.required("Required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  r_postal_code: Yup.string().when("r_is_standard_address", {
    is: true,
    then: (schema) => schema.required("Required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  dob: Yup.date()
    .required("Required")
    .test("is-adult", "Must be at least 18 years old", (value) => {
      const currentDate = new Date();
      const dob = new Date(value);
      const age = Math.floor((currentDate - dob) / (365.25 * 24 * 60 * 60 * 1000));
      // console.log("age", age);
      return age >= 18;
    }),
});

export default function Step4a({ activeStep, handleNext, handleBack, updateEntityLastStep }) {
  const { user } = useAuth();
  const { eid } = useParams();
  const [entityData, setEntityData] = useState({ per_share_value: 0.01, official_business_email: "blank" });
  const [hasDirector, setHasDirector] = useState(false);
  const [hasShareholder, setHasShareholder] = useState(false);
  const [hasOfficer, setHasOfficer] = useState(false);
  const [hasInitialShare, setHasInitialShare] = useState(false);
  const [initialShareData, setInitialShareData] = useState();

  const [shareQty, setShareQty] = useState(100);

  const [shareHolderData, setShareHolderData] = useState();
  const [officerData, setOfficerData] = useState({ title: "President" });

  // const [businessStdAddress, setBusinessStdAddress] = useState(true);

  const formik = useFormik({
    initialValues: {
      first_name: user.user_metadata.name_first,
      last_name: user.user_metadata.name_last,
      middle_name: "",
      email: user.email,

      is_canadian_resident: true,
      is_incorporator: true,
      corporation_name: "",
      corporation_number: "",
      b_is_standard_address: true,
      b_lot_address: "",
      b_street_address: "",
      b_street_name: "",
      b_unit: "",
      b_city: "",
      b_country: "",
      b_jurisdiction: "",
      b_postal_code: "",
      is_corporation: false,
      title: "",
      r_is_standard_address: true,
      r_lot_address: "",
      r_street_address: "",
      r_street_name: "",
      r_unit: "",
      r_city: "",
      r_country: "",
      r_jurisdiction: "",
      r_postal_code: "",
      dob: null,
    },
    validationSchema: validationSchema,

    onSubmit: (values) => {
      console.log("Formik Values: ", values);
      handleClickNext();
    },
  });

  // Create a new shareholder, then calls the createShareClass function to create the initial share class
  //  which then creates the initial share

  const createShareholder = async () => {
    // create shareholder with director data
    const { data: newShareholderData, error: newShareholderError } = await supabase
      .from("shareholder")
      .insert([
        {
          first_name: formik.values.first_name,
          last_name: formik.values.last_name,
          middle_name: formik.values.middle_name,
          email: formik.values.email,
          is_canadian_resident: formik.values.is_canadian_resident,
          corporation_name: formik.values.corporation_name,
          corporation_number: formik.values.corporation_number,
          is_standard_address: formik.values.b_is_standard_address,
          lot_address: formik.values.b_lot_address,
          street_address: formik.values.b_street_address,
          street_name: formik.values.b_street_name,
          unit: formik.values.b_unit,
          city: formik.values.b_city,
          country: formik.values.b_country,
          jurisdiction: formik.values.b_jurisdiction,
          postal_code: formik.values.b_postal_code,
          entity_id: eid,
          linked_user: user.id,
        },
      ])
      .select();
    if (newShareholderError) {
      console.log("shareholder insert error", newShareholderError);
      return;
    }

    // New shareholder created
    if (newShareholderData.length > 0) {
      console.log("shareholder data created:", newShareholderData[0]);
      setShareHolderData(newShareholderData[0]);

      // Create the initial share class: common
      createShareClass(newShareholderData[0].id);
    }

    console.log("inserted shareholder data", newShareholderData);
  };

  // Multi-share class support

  const createInitialShare = async (shareData) => {
    console.log("createinitialshare:shareholderdata: ", shareHolderData);
    const { data, error } = await supabase
      .from("share")
      .insert({
        share_class_id: shareData.share_class_id,
        shareholder_id: shareData.shareholder_id,
        entity_id: shareData.entity_id,
        qty: shareData.qty,
        consideration: shareData.consideration,
      })
      .select();

    console.log("created initial share", data);
  };

  const createShareClass = async (NewSharedHolderId) => {
    console.log("createShareClass: new shareholder ID: ", NewSharedHolderId);

    // Check if the share class exists
    const { data: shareClassData, error: shareClassError } = await supabase.from("share_class").select("*").eq("entity_id", eid).select();

    if (shareClassError) {
      console.log("share class read error", shareClassError);
      return;
    }

    // If there is no share class, create the initial one
    // ! Fix: price_per_share is a number, but the data is coming from entity.per_share_value as a string (text)
    if (shareClassData.length === 0) {
      console.log("share class data", shareClassData);
      const { data, error } = await supabase
        .from("share_class")
        .insert([
          {
            name: "Common",
            entity_id: eid,
            is_certificated: true,
            is_voting: true,
            price_per_share: Number(entityData.per_share_value),
          },
        ])
        .select();

      if (error) {
        console.log("share class insert error", error);
        return;
      }
      console.log("Initial common share class created", data);
      toast.success("Initial common share class created");

      // create the share and link it to the initial user
      const shareData = {
        share_class_id: data[0].id,
        shareholder_id: NewSharedHolderId,
        entity_id: eid,
        qty: shareQty,
        consideration: shareQty * entityData.per_share_value,
      };
      createInitialShare(shareData);
      return;
    } else {
      console.log("Initial common share class already created", shareClassData);
    }
  };

  const createOfficer = async () => {
    const { data, error } = await supabase
      .from("officer")
      .insert([
        {
          first_name: formik.values.first_name,
          last_name: formik.values.last_name,
          middle_name: formik.values.middle_name,
          email: formik.values.email,
          is_canadian_resident: formik.values.is_canadian_resident,
          corporation_name: formik.values.corporation_name,
          corporation_number: formik.values.corporation_number,
          is_standard_address: formik.values.b_is_standard_address,
          lot_address: formik.values.b_lot_address,
          street_address: formik.values.b_street_address,
          street_name: formik.values.b_street_name,
          unit: formik.values.b_unit,
          city: formik.values.b_city,
          country: formik.values.b_country,
          jurisdiction: formik.values.b_jurisdiction,
          postal_code: formik.values.b_postal_code,
          title: officerData.title,
          entity_id: eid,
          linked_user: user.id,
        },
      ])
      .select();
    if (error) {
      console.log("officer insert error", error);
      return;
    }

    if (data.length > 0) {
      console.log("officer data", data[0]);
      setOfficerData(data[0]);
    }
    console.log("inserted officer data", data);
  };

  // handle data update
  const handleClickNext = async () => {
    console.log("handleClickNext");
    // Trigger a validation of the form
    // formik.validateForm();
    // formik.setTouched(true);

    // validate formik

    if (shareQty < 1) {
      toast.error("Share quantity must be greater than 0");
      return;
    }

    // Form has errors
    if (Object.keys(formik.errors).length > 0) {
      console.log("form errors", formik.errors);
      formik.setTouched(formik.errors);
      return;
    }

    // If Form is valid, proceed
    if (Object.keys(formik.errors).length === 0) {
      console.log("no errors");
      console.log(formik.values);

      // Update entity with official business email and per share value
      // * Change - 04/19 - Remove Official Business Email from form - replace with current user email
      // * Was entityData.official_business_email
      const { data, error } = await supabase
        .from("entity")
        .update({
          official_business_email: user.email,
          per_share_value: entityData.per_share_value === "" ? "0.01" : entityData.per_share_value,
        })
        .eq("id", eid);
      if (error) {
        console.log("entity update error", error);
        return;
      }

      // check if director exists - hasDirector is set in useEffect
      if (hasDirector) {
        // update director, officer, shareholder into supabase
        const { data, error } = await supabase
          .from("director")
          .update({
            first_name: formik.values.first_name,
            last_name: formik.values.last_name,
            middle_name: formik.values.middle_name,
            email: formik.values.email,
            is_canadian_resident: formik.values.is_canadian_resident,
            is_incorporator: formik.values.is_incorporator,
            corporation_name: formik.values.corporation_name,
            corporation_number: formik.values.corporation_number,
            b_is_standard_address: formik.values.b_is_standard_address,
            b_lot_address: formik.values.b_lot_address,
            b_street_address: formik.values.b_street_address,
            b_street_name: formik.values.b_street_name,
            b_unit: formik.values.b_unit,
            b_city: formik.values.b_city,
            b_country: formik.values.b_country,
            b_jurisdiction: formik.values.b_jurisdiction,
            b_postal_code: formik.values.b_postal_code,
            is_corporation: formik.values.is_corporation,
            title: formik.values.title,
            r_is_standard_address: formik.values.r_is_standard_address,
            r_lot_address: formik.values.r_lot_address,
            r_street_address: formik.values.r_street_address,
            r_street_name: formik.values.r_street_name,
            r_unit: formik.values.r_unit,
            r_city: formik.values.r_city,
            r_country: formik.values.r_country,
            r_jurisdiction: formik.values.r_jurisdiction,
            r_postal_code: formik.values.r_postal_code,
            dob: formik.values.dob,
          })
          .eq("entity_id", eid)
          .single();
        if (error) {
          console.log("error: update director data", error);
          return;
        }
        console.log("ok: update director data", data);
        // handleNext();
      } else {
        console.log("formikvalues:", formik.values);
        // insert director, officer, shareholder into supabase
        const { data, error } = await supabase
          .from("director")
          .insert([{ ...formik.values, entity_id: eid, linked_user: user.id }])
          .single();
        if (error) {
          console.log("error: insert director data", error);
          return;
        }
        console.log("ok: insert director data", data);

        // create shareholder
        // createShareholder();

        // create share class: initial common
        // createShareClass();

        // create officer
        // createOfficer();
      }
    }

    // if no share holder, create one
    // if (Object.keys(shareHolderData).length === 0) {
    if (!hasShareholder) {
      console.log("no shareholders, creating one...");

      createShareholder();

      // otherise update shareholder that exists
      // } else if (Object.keys(shareHolderData).length > 0) {
    } else if (hasShareholder) {
      console.log("updating shareholder...");
      console.log("shareHolderData", shareHolderData);
      const { data, error } = await supabase.from("shareholder").update(shareHolderData).eq("id", shareHolderData.id).single();

      if (error) {
        console.log("error: update shareholder data", error);
        toast.error("Error updating shareholder");
        return;
      }
      console.log("ok: update shareholder data", data);
    }

    // if no officer, create one
    // if (!officerData.id) {
    // if (Object.keys(officerData).length === 0) {
    if (!hasOfficer) {
      console.log("no officers, creating one...");
      createOfficer();

      // otherise update officer that exists
      // } else if (Object.keys(officerData).length > 0) {
    } else if (hasOfficer) {
      console.log("updating officer...");
      const { data, error } = await supabase.from("officer").update(officerData).eq("id", officerData.id).single();
      if (error) {
        console.log("error: update officer data", error);
        toast.error("Error updating officer");
        return;
      }
      console.log("updated officer", data);
    }

    // If there is an initial share, update it
    if (hasInitialShare) {
      console.log("updating initial share...");
      const { data, error } = await supabase
        .from("share")
        .update({ qty: shareQty, consideration: shareQty * entityData.per_share_value })
        .eq("id", initialShareData.id)
        .single();
      if (error) {
        console.log("error: update initial share data", error);
        toast.error("Error updating initial share");
        return;
      }
      console.log("ok: update initial share data", data);
    }

    // Edge care: shareholder was created, but the share class and share were not created
    if (shareHolderData && !hasInitialShare) {
      // There is no initial share, create the initial share class
      console.log("Shareholder exists, but no initial share - create initial share class and share linked to shareholder...");
      createShareClass(shareHolderData.id);
    }

    updateEntityLastStep(4);
    handleNext();
  };

  // onload fetch data for this step from supabase
  useEffect(() => {
    console.log("useEffect: user:", user);

    //? =========================================================================
    //? Check if director exists, set hasDirector to true if exists and set formik values to director data
    //? =========================================================================
    const getDirectorData = async () => {
      const { data, error } = await supabase.from("director").select("*").eq("entity_id", eid).select();

      if (error) {
        console.log("error: useEffect: get director data", error);
        return;
      }
      console.log("useEffect: get director data", data.length);

      if (data.length > 0) {
        // First record Only
        console.log("got data, not null!");
        console.log("useEffect: get director data", data);
        formik.setValues(data[0]);
        setHasDirector(true);
        console.log("useEffect: hasDirector:", hasDirector);
      } else {
        setHasDirector(false);
        console.log("useEffect: hasDirector:", hasDirector);
      }
    };
    const getEntityData = async () => {
      const { data, error } = await supabase.from("entity").select("*").eq("id", eid).select();

      if (error) {
        console.log("error: useEffect: get entity data", error);
        return;
      }
      console.log("useEffect: get entity data", data.length);
      console.log("useEffect: get entity data", data[0]);

      if (data.length > 0) {
        // First record Only

        setEntityData({ ...data[0], per_share_value: data[0].per_share_value ? data[0].per_share_value : 0.01 });
        // console.log("official_business_email:", data[0].official_business_email);
        // formik.values.official_business_email = data[0].official_business_email;
        // formik.setFieldValue("official_business_email", data[0].official_business_email);
      }
    };

    const getShareholderData = async () => {
      const { data, error } = await supabase.from("shareholder").select("*").eq("entity_id", eid).select();

      if (error) {
        console.log("error: useEffect: get shareholder data", error);
        return;
      }
      console.log("useEffect: get shareholder data", data.length);

      if (data.length > 0) {
        // First record Only
        console.log("Found a shareholder, not null!");
        console.log("useEffect: get shareholder data", data);
        setShareHolderData(data[0]);
        setHasShareholder(true);
      } else {
        setHasShareholder(false);
      }
    };

    const getOfficerData = async () => {
      const { data, error } = await supabase.from("officer").select("*").eq("entity_id", eid).select();

      if (error) {
        console.log("error: useEffect: get officer data", error);
        return;
      }
      console.log("useEffect: get officer data", data.length);

      if (data.length > 0) {
        // First record Only

        console.log("useEffect: get officer data", data);
        setOfficerData(data[0]);
        setHasOfficer(true);
      } else {
        setHasOfficer(false);
      }
    };

    const getShareData = async () => {
      const { data, error } = await supabase.from("share").select("*").eq("entity_id", eid).select();

      if (error) {
        console.log("error: useEffect: get share data", error);
        return;
      }
      console.log("useEffect: get share data", data.length);
      if (data.length > 0) {
        setHasInitialShare(true);
        setShareQty(data[0].qty);
        setInitialShareData(data[0]);
      } else {
        setHasInitialShare(false);
      }
    };

    getDirectorData();
    getEntityData();
    getShareholderData();
    getOfficerData();
    getShareData();
  }, []);

  // console.log("initialData", initialData);

  // Scroll to top of page when this component is loaded
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeStep]);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        {/* Director information */}
        <Stack direction="column" spacing={2} margin={3}>
          <Box
            flexDirection={"column"}
            display="flex"
            columnGap={2}
            rowGap={2}
            mb={2}
            // alignItems={{ xs: "flex-start", sm: "center" }}
          >
            <Typography variant="h5">Director Information</Typography>
            <Typography variant="subtitle1">
              After incorporation, you will be elected initially as the sole director of your corporation. As part of that process, please confirm
              your residency and date of birth.
            </Typography>
          </Box>

          <Stack>
            <Box
              flexDirection={{ xs: "column", sm: "row" }}
              display="flex"
              alignItems={{ xs: "flex-start", sm: "center" }}
              columnGap={2}
              rowGap={2}
              mb={2}
            >
              <Stack direction="row" spacing={2} justifyContent="start" alignItems={"center"}>
                <FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="is_canadian_resident"
                        id="is_canadian_resident"
                        checked={formik.values.is_canadian_resident}
                        value={formik.values.is_canadian_resident}
                        onChange={(e) => {
                          formik.setFieldValue("is_canadian_resident", e.target.checked);
                        }}
                      />
                    }
                    label="I am a Canadian Resident"
                  />
                </FormControl>
                <HelpPopover>
                  <Stack spacing={1} p={3} maxWidth={300}>
                    <Typography sx={{ p: 1 }}>
                      Canadian residency is not a requirement to be a director of an Ontario corporation. However, this information is still required
                      by the government as part of the incorporation process.
                    </Typography>
                  </Stack>
                </HelpPopover>
              </Stack>
              {/* <Stack direction="row" spacing={2} justifyContent={"space-between"} alignContent={"center"} mb={3}> */}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                {/* <DemoContainer components={["DatePicker"]}> */}
                <Stack direction="row" spacing={2} justifyContent="start" alignItems={"center"}>
                  <FormControl sx={{ width: "200px" }}>
                    <DatePicker
                      name="dob"
                      value={dayjs(formik.values.dob)}
                      label="Date of birth"
                      onChange={(newValue) => {
                        console.log(Date.parse(newValue));
                        console.log(dayjs(newValue).format("YYYY-MM-DD"));
                        // formik.setFieldValue("dob", Date.parse(newValue));
                        formik.setFieldValue("dob", newValue);
                      }}
                      onBlur={formik.handleBlur}
                      error={formik.touched.dob && Boolean(formik.errors.dob)}
                      helperText={formik.touched.dob && formik.errors.dob ? formik.errors.dob : null}
                    />
                  </FormControl>
                  <HelpPopover>
                    <Stack spacing={1} p={3} maxWidth={300}>
                      <Typography sx={{ p: 1 }}>A director of an Ontario corporation must be at least 18 years of age.</Typography>
                    </Stack>
                  </HelpPopover>
                </Stack>

                <Typography color="red">{formik.errors.dob ? "Invalid DOB. Must be 18 years or older" : null}</Typography>
              </LocalizationProvider>
              {/* </Stack> */}
            </Box>

            <Box flexDirection={{ xs: "column", sm: "row" }} display="flex" columnGap={2} rowGap={2} mb={2}>
              <FormControl>
                <TextField
                  disabled
                  fullWidth
                  name="first_name"
                  // label="First Name"
                  value={formik.values.first_name}
                  onChange={formik.handleChange}
                  error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                  helperText={formik.touched.first_name && formik.errors.first_name}
                  onBlur={formik.handleBlur}
                  // sx={{ width: "25ch" }}
                />
              </FormControl>
              <FormControl>
                <TextField
                  disabled
                  fullWidth
                  name="middle_name"
                  label="Middle Name"
                  // fullWidth={{ sm: true, md: false }}
                  value={formik.values.middle_name}
                  onChange={formik.handleChange}
                  error={formik.touched.middle_name && Boolean(formik.errors.middle_name)}
                  helperText={formik.touched.middle_name && formik.errors.middle_name}
                  onBlur={formik.handleBlur}
                  // sx={{ width: "25ch" }}
                />
              </FormControl>
              <FormControl>
                <TextField
                  disabled
                  fullWidth
                  name="last_name"
                  // label="Last Name"
                  value={formik.values.last_name}
                  onChange={formik.handleChange}
                  error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                  helperText={formik.touched.last_name && formik.errors.last_name}
                  onBlur={formik.handleBlur}
                  // sx={{ width: "25ch" }}
                />
              </FormControl>
            </Box>
            <Box display="flex" flexDirection={{ xs: "column", sm: "row" }} columnGap={2} rowGap={2} mb={2}>
              <FormControl fullWidth>
                <TextField
                  disabled
                  fullWidth
                  name="email"
                  label="Email"
                  // fullWidth={{ sm: true, md: false }}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  onBlur={formik.handleBlur}
                  // sx={{ width: "25ch" }}
                />
              </FormControl>
            </Box>

            <Box
              flexDirection={"column"}
              display="flex"
              columnGap={2}
              rowGap={2}
              mb={2}
              // alignItems={{ xs: "flex-start", sm: "center" }}
            >
              <Typography variant="h5">Officer Information</Typography>
            </Box>
            <Box display="flex" flexDirection={{ xs: "column", md: "row" }} columnGap={2} rowGap={2} mb={2}>
              <Box display="flex" flexDirection={"column"} columnGap={2} rowGap={2} mb={2}>
                {/* <Typography variant="h6">Title</Typography> */}
                <Stack direction="row" spacing={2} justifyContent="start" alignItems={"center"}>
                  <Typography variant="subtitle1">What title do you want as an officer of your company?</Typography>
                  <HelpPopover>
                    <Stack spacing={1} p={3} maxWidth={300}>
                      <Typography sx={{ p: 1 }}>
                        You can change titles and add other officers after incorporation with the help of a lawyer.
                      </Typography>
                    </Stack>
                  </HelpPopover>
                </Stack>
                <FormControl sx={{ width: "300px" }}>
                  <InputLabel id="officer_title">Title</InputLabel>
                  <Select
                    name="officer_title"
                    label="Title"
                    value={officerData.title ? officerData.title : "President"}
                    onChange={(e) => setOfficerData({ ...officerData, title: e.target.value })}
                    error={officerData.title === ""}
                  >
                    {officer_titles.map((title) => (
                      <MenuItem key={title.id} value={title.name}>
                        {title.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
            <Box display="flex" flexDirection={"column"} columnGap={2} rowGap={2} mb={2}>
              <Box
                flexDirection={"column"}
                display="flex"
                columnGap={2}
                rowGap={2}
                mb={2}
                // alignItems={{ xs: "flex-start", sm: "center" }}
              >
                <Typography variant="h5">Shareholder Information</Typography>
              </Box>
              <Box display="flex" flexDirection={"column"} columnGap={2} rowGap={2} mb={2}>
                {/* <Typography variant="h6">Shareholder</Typography> */}
                <Stack direction="row" spacing={2} justifyContent="start" alignItems={"center"}>
                  <Typography variant="subtitle1">
                    How many common shares do you want to have issued initially to yourself as sole shareholder?
                  </Typography>
                  <HelpPopover>
                    <Stack spacing={1} p={3} maxWidth={300}>
                      <Typography sx={{ p: 1 }}>
                        Common Shares are the most basic class of shares and simplest to get you started. After incorporation, you can always issue
                        more shares to yourself and other shareholders, and add other share classes after incorporation with the help of a lawyer.
                      </Typography>
                    </Stack>
                  </HelpPopover>
                </Stack>
                <FormControl sx={{ width: "300px" }}>
                  <InputLabel id="shareholder_shares">Shares</InputLabel>
                  <Select
                    name="shareholder_shares"
                    label="Shares"
                    value={shareQty ?? 100}
                    onChange={(e) => setShareQty(Number(e.target.value))}
                    error={shareQty === ""}
                  >
                    {share_options.map((choice) => (
                      <MenuItem key={choice.id} value={choice.option}>
                        {choice.option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box display="flex" flexDirection={"column"} columnGap={2} rowGap={2} mb={2}>
                {/* <Typography variant="h6">Shareholder</Typography> */}
                <Stack direction="row" spacing={2} justifyContent="start" alignItems={"center"}>
                  <Typography variant="subtitle1">Per Share value ($)</Typography>
                  <HelpPopover>
                    <Stack spacing={1} p={3} maxWidth={300}>
                      <Typography sx={{ p: 1 }}>
                        Select a dollar amount for each common share that you will be issuing. These are usually nominal amounts to give legal effect
                        to the fact that you’ve actually paid your company in exchange for those shares. We won’t actually be taking any money from
                        you or processing any payments. This amount will simply be recorded on your new corporation’s share register as shares that
                        have been paid for.
                      </Typography>
                    </Stack>
                  </HelpPopover>
                </Stack>
                <FormControl sx={{ width: "300px" }}>
                  <InputLabel id="shareholder_share_value">Per Share Value</InputLabel>
                  <Select
                    name="shareholder_shares"
                    label="Per Share Value"
                    value={entityData.per_share_value ? entityData.per_share_value : 0.01}
                    onChange={(e) => setEntityData({ ...entityData, per_share_value: e.target.value })}
                    error={entityData.per_share_value === 0}
                  >
                    {share_prices.map((choice) => (
                      <MenuItem key={choice.id} value={choice.share_value}>
                        {choice.share_value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>

            {/* BUSINESS ADDRESS */}
            <Box display="flex" flexDirection={"column"} columnGap={2} rowGap={2} mb={2}>
              <Typography variant="h5">Business Address</Typography>
              <Typography variant="subtitle1">
                Please provide us with a business address for you as the initial sole director and officer of your corporation that can be included in
                the Articles of Incorporation. Please note that this information goes into your corporation’s public record.
              </Typography>
              <Box display={"flex"} flexDirection={"row"}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    formik.setFieldValue("b_lot_address", entityData.ra_lot_address);
                    formik.setFieldValue("b_street_address", entityData.ra_street_address);
                    formik.setFieldValue("b_street_name", entityData.ra_street_name);
                    formik.setFieldValue("b_unit", entityData.ra_unit ? entityData.ra_unit : "");
                    formik.setFieldValue("b_city", entityData.ra_city);
                    formik.setFieldValue("b_jurisdiction", entityData.ra_jurisdiction);
                    formik.setFieldValue("b_postal_code", entityData.ra_postal_code);
                    formik.setFieldValue("b_country", entityData.ra_country);
                  }}
                >
                  Use Registered Office Address
                </Button>
              </Box>
              <Box flexDirection={{ xs: "column", sm: "row" }} display="flex" columnGap={2} rowGap={2} mb={2}>
                <FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="b_is_standard_address"
                        id="b_is_standard_address"
                        checked={!formik.values.b_is_standard_address}
                        // value={formik.values.b_is_standard_address}
                        onChange={(e) => formik.setFieldValue("b_is_standard_address", !e.target.checked)}
                        // formik.setFieldValue("b_is_standard_address", !e.target.checked);
                      />
                    }
                    label={`Is Lot Address`}
                  />
                </FormControl>
              </Box>

              <Box
                display={formik.values.b_is_standard_address ? "flex" : "none"}
                flexDirection={{ xs: "column", md: "row" }}
                rowGap={2}
                columnGap={2}
              >
                <FormControl size="small">
                  <TextField
                    name="b_street_address"
                    label="Street Number"
                    fullWidth
                    value={formik.values.b_street_address}
                    onChange={formik.handleChange}
                    error={formik.touched.b_street_address && Boolean(formik.errors.b_street_address)}
                    helperText={formik.touched.b_street_address && formik.errors.b_street_address}
                    onBlur={formik.handleBlur}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    name="b_street_name"
                    label="Street Name"
                    fullWidth
                    value={formik.values.b_street_name}
                    onChange={formik.handleChange}
                    error={formik.touched.b_street_name && Boolean(formik.errors.b_street_name)}
                    helperText={formik.touched.b_street_name && formik.errors.b_street_name}
                    onBlur={formik.handleBlur}
                  />
                </FormControl>
                <FormControl size="xs">
                  <TextField
                    name="b_unit"
                    label="Unit"
                    fullWidth
                    value={formik.values.b_unit}
                    onChange={formik.handleChange}
                    // error={formik.touched.b_unit && Boolean(formik.errors.b_unit)}
                    // helperText={formik.touched.b_unit && formik.errors.b_unit}
                    // onBlur={formik.handleBlur}
                  />
                </FormControl>
              </Box>
              <Box display={formik.values.b_is_standard_address ? "none" : "flex"}>
                {/* add conditional display: block / none for checkbox */}
                {/* {!formik.values.b_is_standard_address && ( */}
                <TextField
                  name="b_lot_address"
                  label="Lot Address"
                  fullWidth
                  value={formik.values.b_lot_address}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.b_lot_address && Boolean(formik.errors.b_lot_address)}
                  helperText={formik.touched.b_lot_address && formik.errors.b_lot_address}
                />
                {/* // )} */}
              </Box>

              <Box
                display={formik.values.b_is_standard_address ? "flex" : "none"}
                flexDirection={{ xs: "column", md: "row" }}
                rowGap={2}
                columnGap={2}
              >
                <TextField
                  name="b_city"
                  label="City"
                  value={formik.values.b_city}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.b_city && Boolean(formik.errors.b_city)}
                  helperText={formik.touched.b_city && formik.errors.b_city}
                />
                <TextField
                  name="b_jurisdiction"
                  label="Province"
                  value={formik.values.b_jurisdiction}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.b_jurisdiction && Boolean(formik.errors.b_jurisdiction)}
                  helperText={formik.touched.b_jurisdiction && formik.errors.b_jurisdiction}
                />
                <TextField
                  name="b_postal_code"
                  label="Postal Code"
                  value={formik.values.b_postal_code}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.b_postal_code && Boolean(formik.errors.b_postal_code)}
                  helperText={formik.touched.b_postal_code && formik.errors.b_postal_code}
                />
                <FormControl sx={{ width: "300px" }}>
                  <InputLabel id="b_country">Country</InputLabel>
                  <Select
                    name="b_country"
                    label="Country"
                    value={formik.values.b_country}
                    onChange={formik.handleChange}
                    error={formik.touched.b_country && Boolean(formik.errors.b_country)}
                  >
                    {countries
                      .sort((a, b) => (a.name.common > b.name.common && 1) || -1)
                      .map((country) => (
                        <MenuItem key={country.name.common} value={country.name.common}>
                          {country.name.common}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
            {/* ================================================================================ */}
            {/* RESIDENTIAL ADDRESS */}
            <Box display="flex" flexDirection={"column"} columnGap={2} rowGap={2} mb={2} mt={2}>
              <Typography variant="h5">Residential Address</Typography>
              <Typography variant="subtitle1">
                Please provide us with your residential address. We need this for the Registers and Ledgers of your corporation, which form part of
                SkyLaunch&apos;s incorporation package. This information is kept only in your corporation&apos;s online minute book and does not go
                into the public record.
              </Typography>
              <Box display={"flex"} flexDirection={"row"}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    formik.setFieldValue("r_lot_address", entityData.ra_lot_address);
                    formik.setFieldValue("r_street_address", entityData.ra_street_address);
                    formik.setFieldValue("r_street_name", entityData.ra_street_name);
                    formik.setFieldValue("r_unit", entityData.ra_unit ? entityData.ra_unit : "");
                    formik.setFieldValue("r_city", entityData.ra_city);
                    formik.setFieldValue("r_jurisdiction", entityData.ra_jurisdiction);
                    formik.setFieldValue("r_postal_code", entityData.ra_postal_code);
                    formik.setFieldValue("r_country", entityData.ra_country);
                  }}
                >
                  Use Registered Office Address
                </Button>
              </Box>
              <Box flexDirection={{ xs: "column", sm: "row" }} display="flex" columnGap={2} rowGap={2} mb={2}>
                <FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="r_is_standard_address"
                        id="r_is_standard_address"
                        checked={!formik.values.r_is_standard_address}
                        onChange={(e) => formik.setFieldValue("r_is_standard_address", !e.target.checked)}
                        // value={formik.values.r_is_standard_address}
                        // onChange={(e) => {
                        //   formik.setFieldValue("r_is_standard_address", !e.target.checked);
                        // }}
                      />
                    }
                    label={`Is Lot Address`}
                  />
                </FormControl>
              </Box>

              <Box
                display={formik.values.r_is_standard_address ? "flex" : "none"}
                flexDirection={{ xs: "column", md: "row" }}
                rowGap={2}
                columnGap={2}
              >
                <FormControl size="small">
                  <TextField
                    name="r_street_address"
                    label="Street Number"
                    fullWidth
                    value={formik.values.r_street_address}
                    onChange={formik.handleChange}
                    error={formik.touched.r_street_address && Boolean(formik.errors.r_street_address)}
                    helperText={formik.touched.r_street_address && formik.errors.r_street_address}
                    onBlur={formik.handleBlur}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    name="r_street_name"
                    label="Street Name"
                    fullWidth
                    value={formik.values.r_street_name}
                    onChange={formik.handleChange}
                    error={formik.touched.r_street_name && Boolean(formik.errors.r_street_name)}
                    helperText={formik.touched.r_street_name && formik.errors.r_street_name}
                    onBlur={formik.handleBlur}
                  />
                </FormControl>
                <FormControl size="xs">
                  <TextField
                    name="r_unit"
                    label="Unit"
                    fullWidth
                    value={formik.values.r_unit}
                    onChange={formik.handleChange}
                    error={formik.touched.r_unit && Boolean(formik.errors.r_unit)}
                    helperText={formik.touched.r_unit && formik.errors.r_unit}
                    onBlur={formik.handleBlur}
                  />
                </FormControl>
              </Box>
            </Box>
            {/* </Box> */}

            <Box display={formik.values.r_is_standard_address ? "none" : "flex"}>
              {/* add conditional display: block / none for checkbox */}

              <TextField
                name="r_lot_address"
                label="Lot Address"
                fullWidth
                value={formik.values.r_lot_address}
                onChange={formik.handleChange}
                error={formik.touched.lot_address && Boolean(formik.errors.r_lot_address)}
                helperText={formik.touched.r_lot_address && formik.errors.r_lot_address}
              />
            </Box>

            <Box display={formik.values.r_is_standard_address ? "flex" : "none"} flexDirection={{ xs: "column", md: "row" }} rowGap={2} columnGap={2}>
              <TextField
                name="r_city"
                label="City"
                value={formik.values.r_city}
                onChange={formik.handleChange}
                error={formik.touched.r_city && Boolean(formik.errors.r_city)}
                helperText={formik.touched.r_city && formik.errors.r_city}
              />
              <TextField
                name="r_jurisdiction"
                label="Province"
                value={formik.values.r_jurisdiction}
                onChange={formik.handleChange}
                error={formik.touched.r_jurisdiction && Boolean(formik.errors.r_jurisdiction)}
                helperText={formik.touched.r_jurisdiction && formik.errors.r_jurisdiction}
              />
              <TextField
                name="r_postal_code"
                label="Postal Code"
                value={formik.values.r_postal_code}
                onChange={formik.handleChange}
                error={formik.touched.r_postal_code && Boolean(formik.errors.r_postal_code)}
                helperText={formik.touched.r_postal_code && formik.errors.r_postal_code}
              />
              {/* <Autocomplete
                id="r_country"
                name="r_country"
                options={countries.name}
                renderInput={(params) => <TextField {...params} label="Country" />}
                inputValue={formik.values.r_country}
                onInputChange={(e, value) => {
                  formik.setFieldValue("r_country", value);
                }}
              /> */}
              <FormControl sx={{ width: "300px" }}>
                <InputLabel id="r_country">Country</InputLabel>
                <Select
                  name="r_country"
                  label="Country"
                  value={formik.values.r_country}
                  onChange={formik.handleChange}
                  error={formik.touched.r_country && Boolean(formik.errors.r_country)}
                >
                  {countries
                    .sort((a, b) => (a.name.common > b.name.common && 1) || -1)
                    .map((country) => (
                      <MenuItem key={country.name.common} value={country.name.common}>
                        {country.name.common}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
          </Stack>

          <Stack direction="row" spacing={2} justifyContent={"space-between"}>
            <Button variant="contained" onClick={handleBack}>
              Back
            </Button>

            <Button variant="contained" color="primary" type="submit">
              Next
            </Button>
          </Stack>
        </Stack>
      </form>
      {/* <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography variant="subtitle1">Debug</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <pre>errors:{JSON.stringify(formik.errors, null, 2)}</pre>

          <pre>hasInitialShare:{JSON.stringify(hasInitialShare, null, 2)}</pre>
          <pre>initialShareData:{JSON.stringify(initialShareData, null, 2)}</pre>
          <pre>shareQty:{JSON.stringify(shareQty, null, 2)}</pre>
          <pre>Official Email (Formik):{JSON.stringify(formik.values.official_business_email, null, 2)}</pre>
          <pre>Official Email:{JSON.stringify(entityData.official_business_email, null, 2)}</pre>
          <pre>shareholder:{JSON.stringify(shareHolderData, null, 2)}</pre>
          <pre>officer:{JSON.stringify(officerData, null, 2)}</pre>
          <pre>director:{JSON.stringify(formik.values, null, 2)}</pre>
          <pre>entity:{JSON.stringify(entityData, null, 2)}</pre>
          <pre>user: {JSON.stringify(user, null, 2)}</pre>
        </AccordionDetails>
      </Accordion> */}
      {/* <pre>{JSON.stringify(shareQty, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(entityData.per_share_value, null, 2)}</pre> */}
    </>
  );
}
